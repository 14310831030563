import React, {useState} from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageSlider = () => {

    const slides = [
        {
            image: '/Images/office/IMG_3170-min.png'
        },
        {
            image: '/Images/office/IMG_3171-min.png'
        },
        {
            image: "/Images/office/IMG_3172-min.png"
        },
        {
            image: "/Images/office/IMG_3173-min.png"
        },
        {
            image: "/Images/office/IMG_3174-min.png"
        },
        {
            image: "/Images/office/IMG_3176-min.png"
        },
        {
            image: "/Images/office/IMG_3177-min.png"
        },
        {
            image: "/Images/office/IMG_3178-min.png"
        },
        {
            image: "/Images/office/IMG_3179-min.png"
        },
        {
            image: "/Images/office/IMG_3180-min.png"
        },
        {
            image: "/Images/office/IMG_3181-min.png"
        },
        {
            image: "/Images/office/IMG_3182-min.png"
        },
        {
            image: "/Images/office/IMG_3183-min.png"
        },
        {
            image: "/Images/office/IMG_3184-min.png"
        },
        {
            image: "/Images/office/IMG_3185-min.png"
        },
        {
            image: "/Images/office/IMG_3188-min.png"
        },
        {
            image: "/Images/office/IMG_3190-min.png"
        },
        {
            image: "/Images/office/IMG_3191-min.png"
        },
        {
            image: "/Images/office/IMG_3192-min.png"
        }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    const divStyle = { backgroundImage: `url(${slides[currentIndex].image})` }

    const test = `${slides[currentIndex].image}`

    function MyDiv() {
        return <div className="absolute inset-0 flex items-center justify-center text-lg bg-black">Loading!</div>;
      }

    return (
        <div className='max-w-[1400px] h-[780px] w-full m-auto py-10 sm:py-5 px-4 relative group'>
        <div
          className='w-full h-full rounded-2xl bg-center bg-cover z-40'
        >
            <LazyLoadImage
            src={test}
            alt=''
            className={`w-full h-full z-20 rounded-xl object-cover`}
            beforeLoad={MyDiv}
      />
        </div>
        {/* Left Arrow */}
        <div className='flex justify-center items-center sm:opacity-0 opacity-100 group-hover:opacity-100 duration-500 absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer' onClick={prevSlide}>
            <i className="fa-solid fa-chevron-left text-lg leading-lg text-white opacity-75" onClick={prevSlide}></i>
        </div>
        {/* Right Arrow */}
        <div className='flex justify-center items-center sm:opacity-0 opacity-100 group-hover:opacity-100 duration-500 absolute top-[50%] place-items-center -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer' onClick={nextSlide}>
            <i className="fa-solid fa-chevron-right text-lg leading-lg text-white opacity-75" onClick={nextSlide}></i>
        </div>
        <div className='flex top-4 justify-center py-2'>
          {slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className='text-2xl cursor-pointer'
            >
            </div>
          ))}
        </div>
      </div>
    )
}

export default ImageSlider;