import newborn from "./Figures/Vectors/newborn.svg"
import bottle from "./Figures/Vectors/bottle.svg"
import growth from "./Figures/Vectors/growth.svg"
import certificate from "./Figures/Vectors/certificate.svg"
import evidence1 from "./Figures/Vectors/evidence1.svg"
import evidence2 from "./Figures/Vectors/evidence2.svg"
import record from "./Figures/Vectors/record.svg"
import stethoscope from "./Figures/Vectors/stethoscope.svg"
import vaccine from "./Figures/Vectors/vaccine.svg"

const AboutServices = () => {
    return ( 
        <div className="w-screen h-auto flex-col relative justify-center mt-0 pointer-events-visible" id="about_services">

            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="flex absolute md:w-full md:h-fit w-0 h-0 md:opacity-100 opacity-0 md:z-10 z-0">
                <path fill="#ffffff" fill-opacity="1" d="M0,224L24,208C48,192,96,160,144,128C192,96,240,64,288,85.3C336,107,384,181,432,218.7C480,256,528,256,576,224C624,192,672,128,720,117.3C768,107,816,149,864,186.7C912,224,960,256,1008,224C1056,192,1104,96,1152,85.3C1200,75,1248,149,1296,176C1344,203,1392,181,1416,170.7L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"></path>
            </svg> */}
            <div className="flex flex-col bg-white w-full h-auto items-center text-center pb-12">
                <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 px-7 sm:mt-12 text-gray-900">Υπηρεσίες</h1>
                <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Στόχος του ιατρείου <b>η σωστή</b> και <b>έγκυρη διάγνωση</b>. 
                    Μέλημά μας η υγεία, <b>η σωστή διατροφή</b> και <b>η καλή ανάπτυξη</b> των μικρών μας φίλων. <br></br><br></br>
                    Για το λόγο αυτό σας παρέχουμε:
                </p>

            </div>
            <svg id="" preserveAspectRatio="xMidYMax meet" className="rotate-180 absolute md:w-full md:h-fit w-0 h-0 md:opacity-100 opacity-0 md:z-10 z-0" viewBox="0 0 1600 100" data-height="100">
                <path className="fill-slate-200"  d="M1040,56c0.5,0,1,0,1.6,0c-16.6-8.9-36.4-15.7-66.4-15.7c-56,0-76.8,23.7-106.9,41C881.1,89.3,895.6,96,920,96
                C979.5,96,980,56,1040,56z"></path>
                <path className="fill-slate-200"  d="M1699.8,96l0,10H1946l-0.3-6.9c0,0,0,0-88,0s-88.6-58.8-176.5-58.8c-51.4,0-73,20.1-99.6,36.8
                c14.5,9.6,29.6,18.9,58.4,18.9C1699.8,96,1699.8,96,1699.8,96z"></path>
                <path className="fill-slate-200"  d="M1400,96c19.5,0,32.7-4.3,43.7-10c-35.2-17.3-54.1-45.7-115.5-45.7c-32.3,0-52.8,7.9-70.2,17.8
                c6.4-1.3,13.6-2.1,22-2.1C1340.1,56,1340.3,96,1400,96z"></path>
                <path className="fill-slate-200"  d="M320,56c6.6,0,12.4,0.5,17.7,1.3c-17-9.6-37.3-17-68.5-17c-60.4,0-79.5,27.8-114,45.2
                c11.2,6,24.6,10.5,44.8,10.5C260,96,259.9,56,320,56z"></path>
                <path className="fill-slate-200"  d="M680,96c23.7,0,38.1-6.3,50.5-13.9C699.6,64.8,679,40.3,622.2,40.3c-30,0-49.8,6.8-66.3,15.8
                c1.3,0,2.7-0.1,4.1-0.1C619.7,56,620.2,96,680,96z"></path>
                <path className="fill-slate-200"  d="M-40,95.6c28.3,0,43.3-8.7,57.4-18C-9.6,60.8-31,40.2-83.2,40.2c-14.3,0-26.3,1.6-36.8,4.2V106h60V96L-40,95.6
                z"></path>
                <path className="fill-slate-100"  d="M504,73.4c-2.6-0.8-5.7-1.4-9.6-1.4c-19.4,0-19.6,13-39,13c-19.4,0-19.5-13-39-13c-14,0-18,6.7-26.3,10.4
                C402.4,89.9,416.7,96,440,96C472.5,96,487.5,84.2,504,73.4z"></path>
                <path className="fill-slate-100"  d="M1205.4,85c-0.2,0-0.4,0-0.6,0c-19.5,0-19.5-13-39-13s-19.4,12.9-39,12.9c0,0-5.9,0-12.3,0.1
                c11.4,6.3,24.9,11,45.5,11C1180.6,96,1194.1,91.2,1205.4,85z"></path>
                <path className="fill-slate-100 fill"  d="M1447.4,83.9c-2.4,0.7-5.2,1.1-8.6,1.1c-19.3,0-19.6-13-39-13s-19.6,13-39,13c-3,0-5.5-0.3-7.7-0.8
                c11.6,6.6,25.4,11.8,46.9,11.8C1421.8,96,1435.7,90.7,1447.4,83.9z"></path>
                <path className="fill-slate-100"  d="M985.8,72c-17.6,0.8-18.3,13-37,13c-19.4,0-19.5-13-39-13c-18.2,0-19.6,11.4-35.5,12.8
                c11.4,6.3,25,11.2,45.7,11.2C953.7,96,968.5,83.2,985.8,72z"></path>
                <path className="fill-slate-100"  d="M743.8,73.5c-10.3,3.4-13.6,11.5-29,11.5c-19.4,0-19.5-13-39-13s-19.5,13-39,13c-0.9,0-1.7,0-2.5-0.1
                c11.4,6.3,25,11.1,45.7,11.1C712.4,96,727.3,84.2,743.8,73.5z"></path>
                <path className="fill-slate-100" d="M265.5,72.3c-1.5-0.2-3.2-0.3-5.1-0.3c-19.4,0-19.6,13-39,13c-19.4,0-19.6-13-39-13
                c-15.9,0-18.9,8.7-30.1,11.9C164.1,90.6,178,96,200,96C233.7,96,248.4,83.4,265.5,72.3z"></path>
                <path className="fill-slate-100"  d="M1692.3,96V85c0,0,0,0-19.5,0s-19.6-13-39-13s-19.6,13-39,13c-0.1,0-0.2,0-0.4,0c11.4,6.2,24.9,11,45.6,11
                C1669.9,96,1684.8,96,1692.3,96z"></path>
                <path className="fill-slate-100"  d="M25.5,72C6,72,6.1,84.9-13.5,84.9L-20,85v8.9C0.7,90.1,12.6,80.6,25.9,72C25.8,72,25.7,72,25.5,72z"></path>
                <path className="fill-white"  d="M-40,95.6C20.3,95.6,20.1,56,80,56s60,40,120,40s59.9-40,120-40s60.3,40,120,40s60.3-40,120-40
                s60.2,40,120,40s60.1-40,120-40s60.5,40,120,40s60-40,120-40s60.4,40,120,40s59.9-40,120-40s60.3,40,120,40s60.2-40,120-40
                s60.2,40,120,40s59.8,0,59.8,0l0.2,143H-60V96L-40,95.6z"></path>
            </svg>

            <div className="flex container flex-col w-full h-auto items-center text-center md:space-y-4 md:mt-20 mt-0">
                {/* Dumb divs just to keep the colors */}
                <svg className="md:py-4  ml-96 mt-96 text-teal-500 fill-current h-80 w-80 md:opacity-20 opacity-0 absolute z-5" viewBox="0 0 96.28 96.38"><g data-name="Layer 2"><path d="m96.22 80.06-13.8-64.4a2.37 2.37 0 0 0-1.8-1.8L16.22.06a2.33 2.33 0 0 0-2.8 2L0 93.66a2.39 2.39 0 0 0 2.7 2.7L94.32 83a2.48 2.48 0 0 0 1.9-2.94Zm-8.9-3.8-.2-.2A294.27 294.27 0 0 0 59.22 43a2.35 2.35 0 0 0-3.3 0L8.42 90.46a2.05 2.05 0 0 1-2.9-.1 2 2 0 0 1 .2-2.6l47.4-47.4a2.35 2.35 0 0 0 0-3.3A305.22 305.22 0 0 0 20 9.16a1.94 1.94 0 0 1-.4-2.8 2 2 0 0 1 2.7-.3 293.09 293.09 0 0 1 33.5 28.3 2.23 2.23 0 0 0 3.2 0l13.9-13.9a2.05 2.05 0 0 1 2.9.1 2 2 0 0 1-.2 2.6l-13.9 13.9a2.23 2.23 0 0 0 0 3.2A323.35 323.35 0 0 1 90 73.76a2.1 2.1 0 0 1-.1 2.6 1.79 1.79 0 0 1-2.58-.1Z" data-name="Layer 1"/></g></svg>

                {/* <div className="flex md:flex-row flex-col w-full md:h-28 h-0 opacity-0">
                    <div className="group flex justify-center bg-[#ffa12f] md:w-1/3 w-full md:h-full h-0 bg-opacity-80 md:opacity-100 opacity-0">
                    
                    </div>
                    <div className="flex justify-center bg-[#48d1b7] md:w-1/3 w-full md:h-full h-0 bg-opacity-80 md:opacity-100 opacity-0">
                        
                    </div>
                    <div className="flex justify-center bg-[#c8100e] md:w-1/3 w-full  md:h-full h-0 bg-opacity-80 md:opacity-100 opacity-0">
                        
                    </div>
                </div> */}
                <div className="flex container md:flex-row flex-col w-full md:h-1/2 h-fit lg:max-h-1/2 md:space-x-4 md:px-4">
                    <div className="sm:hover:-translate-y-2 duration-150 flex-col p-5 flex justify-center bg-[#20ac42] md:w-1/3 w-full md:h-full h-auto bg-opacity-80 items-center md:rounded-lg md:drop-shadow-xl">
                        <div className="pointer-events-visible">
                            <img src={newborn} alt="" className="pb-4 h-24 w-24"/>
                        </div>
                        <p class="leading-relaxed body-font font-semibold ">
                        Πλήρη νεογνολογικό έλεγχο/πρόωρα-τελειόμηνα.
                        </p>

                    </div>
                    <div className="sm:hover:-translate-y-2 duration-150 flex-col p-5 flex justify-center bg-[#48d1b7] md:w-1/3 w-full md:h-full h-auto bg-opacity-80 items-center md:rounded-lg md:drop-shadow-xl">
                        <div className="pointer-events-visible">
                            <img src={bottle} alt="" className="pb-4 h-24 w-24"/>
                        </div>
                        <p class="leading-relaxed body-font font-semibold">
                        Προώθηση μητρικού θηλασμού – Συμβουλές διατροφής στη θηλάζουσα μητέρα.
                        </p>
                    </div>
                    <div className="sm:hover:-translate-y-2 duration-150 flex-col p-5 flex justify-center bg-[#c8100e] md:w-1/3 w-full md:h-full h-auto bg-opacity-80 items-center md:rounded-lg md:drop-shadow-xl">
                        <div className="pointer-events-visible">
                            <img src={growth} alt="" className="pb-4 h-24 w-24"/>
                        </div>
                        <p class="leading-relaxed body-font font-semibold">
                        Αναπτυξιολογικό έλεγχο βρεφών/παιδιών/εφήβων.
                        </p>
                    </div>
                </div>
                <div className="flex container md:flex-row flex-col w-full md:h-1/2 h-fit md:space-x-4 md:px-4">
                    <div className="sm:hover:-translate-y-2 duration-150 flex-col p-5 flex justify-center bg-orange-600 md:w-1/3 w-full md:h-full h-auto bg-opacity-80 items-center md:rounded-lg md:drop-shadow-xl">
                        <div className="pointer-events-visible">
                            <img src={vaccine} alt="" className="pb-4 h-24 w-24"/>
                        </div>
                        <p class="leading-relaxed body-font font-semibold">
                        Πλήρη εμβολιαστική κάλυψη.
                        </p>
                    </div>
                    <div className="sm:hover:-translate-y-2 duration-150 flex-col p-5 flex justify-center bg-[#009cd1] md:w-1/3 w-full md:h-full h-auto bg-opacity-80 items-center md:rounded-lg md:drop-shadow-xl">
                        <div className="pointer-events-visible">
                            <img src={record} alt="" className="pb-4 h-24 w-24"/>
                        </div>
                        <p class="leading-relaxed body-font font-semibold">
                        Λήψη ιστορικού, διάγνωση, θεραπεία όλων των λοιμώξεων/παθήσεων που αφορούν την υγεία του νεογνού, του παιδιού και του εφήβου.
                        </p>
                    </div>
                    <div className="sm:hover:-translate-y-2 duration-150 flex-col p-5 flex justify-center bg-[#ffa12f] md:w-1/3 w-full md:h-full h-auto bg-opacity-80 items-center md:rounded-lg md:drop-shadow-xl">
                        <div className="pointer-events-visible">
                            <img src={stethoscope} alt="" className="pb-4 h-24 w-24"/>
                        </div>
                        <p class="leading-relaxed body-font font-semibold">
                        Εξειδικευμένη διάγνωση με χρήση όλων των βοηθητικών εργαλείων (τέστ, οξυμετρία, dextro, …)
                        </p>
                    </div>
                </div>
                <div className="flex container justify-center md:flex-row flex-col w-full md:h-1/2 h-fit md:space-x-4 md:px-4">
                    <div className="sm:hover:-translate-y-2 duration-150 flex-col p-5 flex justify-center bg-[#009ca9] md:w-1/3 w-full md:h-full h-auto bg-opacity-80 items-center md:rounded-lg md:drop-shadow-xl">
                        <div className="pointer-events-visible">
                            <img src={certificate} alt="" className="pb-4 h-24 w-24"/>
                        </div>
                        <p class="leading-relaxed body-font font-semibold">
                        Προληπτική παιδιατρική (check up) – Πιστοποιητικά υγείας.
                        </p>
                    </div>
                    <div className="sm:hover:-translate-y-2 duration-150 flex-col p-5 flex justify-center bg-green-400 md:w-1/3 w-full md:h-full h-auto bg-opacity-80 items-center md:rounded-lg md:drop-shadow-xl">
                        <div className="pointer-events-visible">
                            <img src={evidence1} alt="" className="pb-4 h-24 w-24"/>
                        </div>
                        <p class="leading-relaxed body-font font-semibold">
                        Evidence based medicine.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutServices;