import logo from './logo.svg';
import './App.css';
import Navbar from './Components/NavBar';
import TextBlock from './Components/TextBlock';
import AboutDoctor from './Components/AboutDoctor';
import AboutOffice from './Components/AboutOffice';
import AboutServices from './Components/AboutServices';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import ImageSlider from './Components/ImageSlider';

import { Element } from 'react-scroll'

function App() {
  return (
    <div className="App overflow-hidden bg-gradient-to-tr from-sky-200 to-purple-300 h-auto w-auto">
      
      {/* <header className='sticky top-0 z-50'>
        <Navbar/>
      </header> */}
      <Navbar/>
      <div id='aboutdoctor'>
        <AboutDoctor/>
      </div>
      <div id='aboutservices' className='justify-center'>
        <AboutServices/>
      </div>
      <div id='aboutoffice'>
        <AboutOffice/>
      </div>
      <div id='contact'>
        <Contact/>
      </div>
      <Footer/>
      {/* <div className='snap-start' id="about_doctor">
        <Navbar/>
      </div>
      <div className='snap-start' id="about_doctor">
        <AboutDoctor/>
      </div>
      <div className='snap-start' id="about_office">
        <AboutServices/>
      </div> */}
      {/* <h1 className='text-3xl font-bold underline'>
        Test Change
      </h1>
      <button class="bg-violet-500 rounded-full p-2 m-2 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 ...">
  Save changes
</button> */}
    </div>
  );
}

export default App;
