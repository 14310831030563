const TextBlock = () => {
    return ( 
        <div className="p-4 rounded-xl transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 
        hover:scale-110 hover:bg-indigo-500 duration-300 mt-16 mx-28 max-w-6xl 
        drop-shadow-xl ">
            <p className="">
            Lorem ipsum odor amet, consectetuer adipiscing elit. Quisque dui hendrerit a ac congue. Neque erat taciti varius lacus penatibus aenean gravida. Elit praesent dictumst neque leo suscipit. Eros mollis eros ullamcorper netus mattis imperdiet. Adipiscing tristique ex eu ultrices finibus interdum tincidunt fusce. Augue magna netus gravida malesuada luctus class dictumst erat montes.
            </p>
        </div>
     );
}
 
export default TextBlock;