import React from "react";
import logo from "./Figures/Vectors/logo.svg"


export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [activeLink, setActiveLink] = React.useState('');

  const handleClick = (link) => {
    setActiveLink(link);
    const targetSection = document.getElementById(link);
    targetSection.scrollIntoView({ behavior: 'smooth' });
  };


  return (
  <>
  <nav className="pt-6 pb-6 drop-shadow-xl relative flex flex-wrap items-start justify-between px-2 py-3 bg-white z-50">
    <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div className="flex flex-initial">
      <img src={logo} alt="" className="md:w-24 md:h-24 w-16 h-16 self-center md:mr-0 mr-4"/>
            <h1
              className="title-font text-2xl font-medium text-left pl-1 md:pl-12 text-gray-900 leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
            >
              Βασιλική <br></br> Μαργαριτοπούλου
            </h1>
            <div className="flex flex-nowrap items-center lg:h-auto lg:w-auto h-0 w-0 opacity-0 lg:opacity-100">
            <h1
              className="title-font text-2xl font-medium text-left self-center pl-1 md:pl-12 text-gray-900 leading-relaxed inline-block mr-4 py-2 whitespace-nowrap "
            >
               <i>"Τα πάντα... για τα παιδιά"</i>
            </h1>
            </div>
            
            </div>
            <button
              className="font-medium text-gray-900 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  href="#aboutdoctor"
                  className={`${
                    activeLink === 'aboutdoctor'
                      ? 'font-bold text-gray-900'
                      : 'font-normal text-gray-500'
                  } px-3 py-2 flex items-center text-s font-noto font-bold leading-snug text-white hover:opacity-75`}
                  onClick={() => handleClick('aboutdoctor')}
                >
                  <i className="fa-solid fa-user-doctor text-lg leading-lg text-gray-900 opacity-75"></i><span className="ml-2 font-medium text-gray-900">Η παιδίατρος</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                    href="#aboutservices"
                    className={`${
                      activeLink === 'aboutservices'
                        ? 'font-bold text-gray-900'
                        : 'font-normal text-gray-500'
                    } px-3 py-2 flex items-center text-s font-noto font-bold leading-snug text-white hover:opacity-75`}
                    onClick={() => handleClick('aboutservices')}
                  >
                  <i className="fa-solid fa-stethoscope text-lg leading-lg text-gray-900 opacity-75"></i><span className="ml-2 font-medium text-gray-900">Παροχές</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                    href="#aboutoffice"
                    className={`${
                      activeLink === 'aboutoffice'
                        ? 'font-bold text-gray-900'
                        : 'font-normal text-gray-500'
                    } px-3 py-2 flex items-center text-s font-noto font-bold leading-snug text-white hover:opacity-75`}
                    onClick={() => handleClick('aboutoffice')}
                  >
                  <i className="fa-solid fa-stethoscope text-lg leading-lg text-gray-900 opacity-75"></i><span className="ml-2 font-medium text-gray-900">Το ιατρείο</span>
                </a>
              </li>
              <li className="nav-item">
                  <a
                    href="#contact"
                    className={`${
                      activeLink === 'contact'
                        ? 'font-bold text-gray-900'
                        : 'font-normal text-gray-500'
                    } px-3 py-2 flex items-center text-s font-noto font-bold leading-snug text-white hover:opacity-75`}
                    onClick={() => handleClick('contact')}
                  >
                  <i className="fa-solid fa-phone text-lg leading-lg text-gray-900 opacity-75"></i><span className="ml-2 font-medium text-gray-900">Επικοινωνήστε</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}