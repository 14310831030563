import React from "react";
import ImageSlider from "./ImageSlider.js";

const AboutOffice = () => {
    return ( 
        <div className="w-full content-center relative bg-gradient-to-t from-white via-white md:mt-5 sm:pb-12 pb-0" id="about_office">
            <section class="text-gray-600 body-font mx-2">
                <div class="flex flex-col text-center w-full sm:mb-14">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 mt-16 text-gray-900 ">Το ιατρείο</h1>
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-xl">
                        Ο χώρος του ιατρείου ειναι πλήρως εξοπλισμένος:
                    </p>
                </div>
                <ImageSlider/>

                
            <div class="flex flex-col text-center w-full sm:mb-3">
                    <h1 class="sm:text-xl text-2xl font-medium title-font text-gray-900 "></h1>
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-xl">
                        
                    </p>
                </div>
            </section>
        </div>
     );
}

export default AboutOffice;