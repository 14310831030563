import React from "react";

const Contact = () => {
    return ( 
      <section class="text-gray-600 body-font relative sm:pt-9">
      <div class="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <div class="lg:w-2/3 md:w-1/2 bg-white w-full rounded-lg overflow-hidden sm:mr-10 sm:p-10 p-32 flex items-end justify-start relative">
          <iframe className="inset-0 absolute"
            width="100%" height="100%" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDAzRyEvaWIQPPXaToh706bel3UmpXihKY&q=Βασιλική Μαργαριτοπούλου, Ογλ, Βόλος, Greece">
          </iframe>
          {/* <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
            <div class="lg:w-1/2 px-6">
              <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
              <p class="mt-1">Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter</p>
            </div>
            <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
              <a class="text-indigo-500 leading-relaxed">example@email.com</a>
              <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
              <p class="leading-relaxed">123-456-7890</p>
            </div>
          </div> */}
        </div>
        <div class="lg:w-1/3 md:w-1/2 px-10 sm:px-10 bg-white flex flex-col md:ml-auto w-full py-6 md:py-8 mt-8 md:mt-0 rounded-xl">
          <h2 class="text-gray-900 text-lg mb-1 font-medium title-font border-b-2 border-gray-200 border-opacity-60">Ωράριο*</h2>
          <div className="grid grid-cols-3 gap-x-3 text-start border-b-2 border-gray-200 border-opacity-60 pb-4 pt-3">
            <p className="text-gray-900 sm:text-lg text-m font-medium title-font">Δευτέρα:</p>
            <p class="leading-relaxed mb-0 text-gray-600">09:30 - 13:00</p>
            <p class="leading-relaxed mb-0 text-gray-600">17:30 - 21:00</p>

            <p className="text-gray-900 sm:text-lg text-m font-medium title-font">Τρίτη:</p>
            <p class="leading-relaxed mb-0 text-gray-600">09:30 - 13:00</p>
            <p class="leading-relaxed mb-0 text-gray-600">17:30 - 21:00</p>

            <p className="text-gray-900 sm:text-lg text-m font-medium title-font">Τετάρτη:</p>
            <p class="leading-relaxed mb-0 text-gray-600">09:30 - 15:00</p>
            <p class="leading-relaxed mb-0 text-gray-600">Κλειστά</p>
            
            <p className="text-gray-900 sm:text-lg text-m font-medium title-font">Πέμπτη:</p>
            <p class="leading-relaxed mb-0 text-gray-600">09:30 - 13:00</p>
            <p class="leading-relaxed mb-0 text-gray-600">17:30 - 21:00</p>

            <p className="text-gray-900 sm:text-lg text-m font-medium title-font">Παρασκευή:</p>
            <p class="leading-relaxed mb-0 text-gray-600">09:30 - 13:00</p>
            <p class="leading-relaxed mb-0 text-gray-600">Κλειστά</p>

            <p className="text-gray-900 sm:text-lg text-m font-medium title-font">Σάββατο:</p>
            <p class="leading-relaxed mb-0 text-gray-600">Κλειστά</p>
            <p class="leading-relaxed mb-0 text-gray-600"> </p>

            <p className="text-gray-900 sm:text-lg text-m font-medium title-font">Κυριακή:</p>
            <p class="leading-relaxed mb-0 text-gray-600">Κλειστά</p>
            <p class="leading-relaxed mb-0 text-gray-600"> </p>
          </div>
          <h2 class="text-gray-900 text-lg mb-1 font-medium title-font text-left pt-4">Τηλ. επικοινωνίας: <i className="fa-solid fa-phone text-lg leading-lg text-gray-900 opacity-75"></i> +30 24210 32200</h2>
          <p class="text-xs text-gray-500 mt-3">* το ωράριο μπορεί να διαφέρει εν καιρό εορτών.</p>
        </div>
      </div>
    </section>
    );
}

export default Contact;